import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {BrowserRouter as Router} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './assets/scss/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modern-drawer/dist/index.css'
import { ErrorBoundary } from 'react-error-boundary';
import Error from './pages/error/error';
import {Provider} from 'react-redux'
import {store,persistor} from './store/index'
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

// Create React root node
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['localStorage','cookie','htmlTag','querystring', 'path' , 'sessionStorage', 'navigator', 'subdomain'],
      caches: ['localStorage'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      requestOptions:{
        cache:'no-store'
      }
    }
  })

root.render(
  //<React.StrictMode>
    <Router>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ErrorBoundary FallbackComponent={Error}>
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} closeButton={false} />
                <Suspense>
                  <App />
                </Suspense>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
